import { loremIpsum, name, surname, fullname, username } from 'react-lorem-ipsum';
import { LoremIpsum } from 'react-lorem-ipsum';
import { Footer } from "./components/footer";
import { Navigation } from "./components/fancynav";
import { Upcoming } from "./components/upcoming";
import { AsSeen } from "./components/asseenon";

const portraitUrl = new URL(
  './images/me\ blue.jpg',
  import.meta.url
);


const topHeaderStart = "Historian, Author"
const topHeaderMiddle = " & "
const topHeaderEnd = "PACEs Science Visionary"

const social = [
  {
    name: 'Connect on LinkedIn',
    enabled: true,
    additionalClassNames: 'social-linkedin',
    href: 'https://linkedin.com/in/acesingrid/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
      </svg>
    ),
  },
  {
    name: 'Facebook',
    enabled: false,
    additionalClassNames: 'social-facebook',
    href: '#',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    enabled: false,
    additionalClassNames: 'social-instagram',
    href: 'https://www.instagram.com/acesingrid/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Listen on iTunes',
    enabled: true,
    additionalClassNames: 'social-itunes',
    href: 'https://podcasts.apple.com/us/podcast/history-culture-trauma/id1612149851',
    icon: (props) => (
<svg width="800px" height="800px" viewBox="-56.24 0 608.728 608.728" {...props}><path d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z" fill="#999"/></svg>
    ),
  },
  {
    name: 'Follow on X',
    enabled: true,
    additionalClassNames: 'social-x',
    href: 'https://twitter.com/acesingrid',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
      </svg>
    ),
  },
]

export function OldIndexPage() {
    return (
        <div className="">
            <div className="inset-0 flex justify-center px-8">
                <div className="w-full max-w-7xl flex flex-col lg:px-8">

                    <Navigation />
                    <main className="flex-auto pt-8">
                        <div className="mt-8 lg:px-8">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-7 lg:gap-x-7 lg:grid-rows-[auto_1fr]">
                                <div className="lg:p-12 p-6 lg:max-w-none">
                                    <img className="flex aspect-square lg:rotate-3 rounded-full object-cover shadow-xl border-1 border-gray-900" src={portraitUrl} />
                                </div>
                                <div className="lg:order-first">
                                    <h1 className="tracking-tight sm:text-7xl text-3xl font-medium">
                                        {topHeaderStart}
                                        <span className="text-[#1b8da3]">{topHeaderMiddle}</span>
                                        {topHeaderEnd}
                                    </h1>
                                    <div className="text-zinc-600 mt-8 space-y-7 lg:text-base text-xl">

                                    <p>Ingrid Cockhren, M.Ed., specializes in co-creating equitable and inclusive environments within organizations, collective impacts, and grassroots movements. Utilizing her knowledge of stress, trauma, human development, and personality, Mrs. Cockhren has been able to translate research concerning diversity/equity/inclusion/belonging (DEIB), trauma-informed, and healing-centered practices into community, workplace, and organizational solutions that suit both traditional and virtual teams.</p>

                                    <p>Ingrid Cockhren&#39;s academic journey is marked by a B.S. in Psychology from Tennessee State University and an M.Ed. in Child Studies from Vanderbilt University’s Peabody College. Mrs. Cockhren specializes in PACE science (positive and adverse childhood experiences), collective trauma, historical trauma/intergenerational transmission, brain development, developmental psychology, and epigenetics. Her diverse career experience includes roles in corporate sales, juvenile justice, family counseling, early childhood education, professional development &amp; training, and community education. Currently, she serves as an adjunct professor, specializing in lifespan developmental psychology, abnormal psychology &amp; minority mental health. She is the former CEO of PACEs Connection, an international social network dedicated to raising awareness of trauma and resilience.</p>

                                    <p>Over the last decade, Ingrid Cockhren’s consulting, facilitation, and DEIB services have significantly impacted her clients and affiliates. Mrs. Cockhren owns Cockhren Consulting, where she coaches leaders and executives and advises hospitals, colleges, schools, non-profits, and other institutions. Ingrid Cockhren is an advocate for women and children and a community change agent. She sits on several boards for organizations that empower and heal women, children, or communities. She is a founding partner of the Black Women’s Health &amp; Healing Collective, an initiative examining Black women’s health equity.</p>

                                    <p>Ingrid Cockhren is a Clarksville, TN native who currently resides in Nashville, TN, with her husband, Jurnell Cockhren, founder of Civic Hacker, a software development consulting agency.</p>
                                    </div>
                                </div>
                                <div className="row-span-2">
                                    <AsSeen />
                                </div>
                                <div className="lg:pl-20">
                                    <ul className="" role="list">
                                        {social.filter(p => !!p.enabled).map((p, item) => {
                                            return <li className="mt-4 flex"><a target="_blank" href={p.href} className={`${p.additionalClassNames} group lg:text-base text-xl font-medium text-zinc-800 flex`}>{p.icon({className: "pb-4 h-16 w-16 lg:h-12 lg:w-12 flex-none bg-white"})}<span className="ml-4">{p.name}</span></a></li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />

                </div>
            </div>
        </div>
    )
}
