

export function Footer() {
  return (
    <footer className="bg-white w-full">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-2 lg:pb-8 sm:py-4 lg:px-8">
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 Cockhren Consulting. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
