const content = [
  {title: 'Historical Trauma', platform: 'Apple', medium: 'audio', link: 'https://podcasts.apple.com/us/podcast/understanding-historical-trauma-with-ingrid-cockhren/id1496190024?i=1000569752970' },
  {title: 'Historical Trauma-Education', platform: 'soundcloud', medium: 'audio', link: 'https://soundcloud.com/mathew-portell-93233744/3-ingrid-cockhren-trauma-informed-educators-network-podcast?utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing'},
  { title: 'Education Equity', platform: 'YouTube', medium: 'video', link: 'https://www.youtube.com/watch?v=OPR1XezsYBU' },
  { title: 'Historical Trauma in America', platform: 'YouTube', medium: 'video', link: 'https://www.youtube.com/watch?v=o9joRdzxOV4'},
  { title: 'Racial Trauma and How to be Anti-racist', platform: 'YouTube', medium: 'audio', link: 'https://www.youtube.com/watch?v=sY0JPGTfV5c&amp;t=32s'},
  { title: 'Dibble Institute: Adolescents, Attachment & ACEs', platform: 'YouTube', medium: 'video', link: 'https://www.youtube.com/watch?v=ND9y53ktpJc&amp;t=1s' },
  { title: 'Antiracism', platform: 'YouTube', medium: 'video', link: 'https://www.youtube.com/watch?v=fV1RsDAn04s&amp;t=77s' },
  { title: 'Historical Trauma in Cali', platform: 'YouTube', medium: 'video', link: 'https://www.youtube.com/watch?v=L3rU2ac8xLI' },
  { title: 'Trauma-informed Education', platform: 'buzzsprout', medium: 'audio', link: 'https://www.buzzsprout.com/2070261/14628515' },
  { title: 'Historical Trauma', platform: 'Snipd', medium: 'audio', link: 'https://share.snipd.com/episode/f04dff4b-3bff-4351-bd5f-f8cb0258b70a' },
  { title: 'Historical Trauma', platform: 'Voice America', medium: 'audio', link: 'https://www.voiceamerica.com/episode/138535/dr-bruce-perry-historical-trauma-and-what-happened-to-you' },
  { title: 'Child Abuse', platform: 'Voice America', medium: 'audio', link: 'https://www.voiceamerica.com/episode/149628/encore-americas-culture-of-child-abuse-pt-1' }
]


import { ChevronRightIcon } from '@heroicons/react/20/solid'

export function MediaTable() {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {content.map((person) => (
        <li key={person.title} className="relative py-5 hover:bg-gray-50">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a href={person.link}>
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      {person.title}
                    </a>
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href={person.link} className="relative truncate hover:underline">
                      {person.link}
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">{person.platform}</p>
                </div>
                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
