const portraitUrl = new URL(
  '../images/me\ blue.jpg',
  import.meta.url
);

export function Statement() {
  return (
    <div className="bg-[#d84480] py-6 flex flex-col text-white">
        <h3 className="text-center text-3xl font-semibold leading-8 mb-2">
          A Message from the Founder
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-3 lg:px-8 lg:gap-x-7 lg:grid-rows-[auto_1fr]">
          <div className="p-4 lg:max-w-lg flex justify-center">
            <img className="aspect-square lg:rotate-3 rounded-full object-cover shadow-xl border-1 border-gray-900 h-40" src={portraitUrl} />
          </div>
          <div className="lg:order-first p-4">
            <div className="mt-8 space-y-1 items-center lg:text-lg text-4xl flex flex-row">
              <p>With over 10 years of experience in child development, psychology, and education, I hold a Master of Education in Applied Child Studies from Vanderbilt University, specializing in social-emotional development of at-risk children.</p>
            </div>
          </div>
        </div>
    </div>
  )
}
