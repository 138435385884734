const stars = [1, 2, 3, 4, 5];

export function AsSeen() {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-0">
        <h2 className="text-4xl font-semibold leading-8 text-gray-900">
          As Seen On <span className="text-2xl text-gray-600">(coming soon)</span>
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none">
        {stars.map((p, i) => {
            return <div></div>
        })}
        </div>
      </div>
    </div>
  )
}
