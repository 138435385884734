const logoUrl = new URL(
  '../images/ilc-logo-green-minimal-740x190.png',
  import.meta.url
);

/*
const portraitUrl = new URL(
  '../images/pexels-june-cropped.jpg',
  import.meta.url
);
*/

const portraitUrl = new URL(
  '../images/AntipathologySeriesMockUpSquare_5_1.png',
  import.meta.url
);


//const HeroHeadline = "History, Trauma & Healing"
const HeroHeadlineTop = "\"Those who fail to learn from"
const HeroHighlight = " HISTORY "
const HeroHeadlineBottom = "are doomed to repeat it.\""
const HeroSubText = "Adopt a transformative systems approach rooted in the SCIENCE of human development. Innovative people-centered, healing-centered, and trauma-informed practices are the FUTURE of work, health, and community engagement & mobilization."
//const HeroSubText = "\"Those who fail to learn from HISTORY are doomed to repeat it.\" Adopt a transformative systems approach rooted in the SCIENCE of human development. Innovative people-centered, healing-centered, and trauma-informed practices are the FUTURE of work, health, and community engagement & mobilization."

export function Hero() {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-8 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-24 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="mt-24 text-2xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-5xl">
              {HeroHeadlineTop}
             <span className="text-[#1b8da3]">{HeroHighlight}</span>
              {HeroHeadlineBottom}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {HeroSubText}
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="https://calendly.com/cockhrenconsulting/initial-consultation-book-me"
                target="_blank"
                className="rounded-md bg-[#1b8da3] px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-teal-700"
              >
                Start Today
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0 p-20">
        <img className="rounded w-auto shadow-xl border-1 border-gray-900 " src={portraitUrl} />
        {/*
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src={portraitUrl}
            alt=""
          />
          */}
        </div>
      </div>
    </div>
  )
}
