const events = [
];

export function Upcoming() {
  return (
    <div className="bg-white w-full row-span-2 py-6">
      <h3 className="text-6xl lg:text-4xl font-semibold">Upcoming Events</h3>
      <div className="max-w-7xl overflow-hidden px-0 py-8">
        <p className="mt-0 text-4xl lg:text-base leading-5 text-gray-500">
          Coming Soon
        </p>
      </div>
    </div>
  )
}
