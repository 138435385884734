import * as React from "react";
import { createRoot } from "react-dom/client";
import { OldIndexPage } from "./oldindex";
import { IndexPage } from "./index";
import { AboutPage } from "./about";
import { MediaPage } from "./media";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/old/",
    element: <OldIndexPage />,
  },
  {
    path: "/",
    element: <IndexPage />,
  },
  {
    path: "/media/",
    element: <MediaPage />,
  },
  {
    path: "/about/",
    element: <AboutPage />,
  },
]);

const app = document.getElementById("app");
const root = createRoot(app);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
